/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import VvVideo from '../assets/videos/vv-v1-2.mp4';
import GridBlock from '../components/gridBlock';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import ImgDisplay from '../components/imgDisplay';
import TopMenu from '../components/topMenu';


const blockBg = img =>
  `url(${img}) center / cover no-repeat fixed padding-box content-box black`;

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query connectOSearch {
      power: file(relativePath: { eq: "power-red.jpg" }) {
        publicURL
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      research: file(relativePath: { eq: "research-green.jpg" }) {
        publicURL
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
        party: file(relativePath: { eq: "party-blue.jpg" }) {
        publicURL
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
        service: file(relativePath: { eq: "service-yellow.jpg" }) {
        publicURL
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const blockCSS = 'flex flex-auto group w-full relative h-1/8 border-0';
  const colorCSS =
    'transition h-full w-full absolute opacity-10 group-hover:opacity-30';
  return (
    <Layout to="/" label="BACK TO HOME" showMenuIcon className="bg-black ">
      <SEO title="Vivo Vibe" />
      <TopMenu></TopMenu>
      <section className="h-screen w-screen">
        <div className="absolute w-screen h-screen">
          <video
            autoPlay
            muted
            playsInline
            loop
            className="object-cover h-full w-full absolute"
          >
            <source src={VvVideo} type="video/mp4" />
          </video>
          <div className="w-full h-full overlay absolute"></div>
        </div>
        <div className="relative flex h-screen w-screen justify-center flex-col fourUpPad max-thirteenfifty">
            <div class="grid text-center text-2xl pFix vivo-cont minussixt">
                What Image Resonates With You The Most?
            </div>
            <div class="grid photo-col the-gap text-3xl text-center pb-10 minusthreetwo">
                <div class="vivo text-white text-left clippers bg-black  w-full">
                   <div class="p-5 pl-20 pr-20">
      <h4 class="grow">INVESTMENT IN YOU!</h4>
                    <p>Vivo Vibe is the culmination of over 20 years of industry leading experience & dramatic success in Network Marketing.  We are incentivized to help you learn, grow, and succeed.</p>                 
      </div>
                </div>
                <div class="vivo text-white lefter">
                    <AniLink 
                     cover
                        direction="right"
                        to="/research"
                        duration={2}
                        bg={blockBg(data.research.publicURL)}                    >
                        <ImgDisplay
                        image={data.research}
                        >
                        </ImgDisplay>                    </AniLink>
                </div>
                <div class="vivo text-white">
                    <AniLink 
                    cover
                        direction="right"
                        to="/serve"
                        duration={2}
                        bg={blockBg(data.service.publicURL)}                    >
          <ImgDisplay
                        image={data.service}
                        >
                        </ImgDisplay>
                    </AniLink>
                </div>
                <div class="vivo text-white lefter">
                    <AniLink 
                     cover
                        direction="right"
                        to="/fun"
                        duration={2}
                        bg={blockBg(data.party.publicURL)}                    >
                        <ImgDisplay
                        image={data.party}
                        >
                        </ImgDisplay>
                    </AniLink>
                </div>
            </div>
            </div>
      </section>

      </Layout>
  );
};

export default IndexPage;
